/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum LocalStorage {
  ACCESS_TOKEN = 'access-token',
  REFRESH_TOKEN = 'refresh-token',
  EMAIL = 'email',
  INPUT_OPTION = 'ecMPSG4nr78kqL16fS1I',
  INPUT_STATUS_BUTTON = 'tGx9dykqVxLy4ekHYtKA6jw',
}

export enum ROUTE {
  LOGIN = 'login',
  ACCOUNT_VERIFICATION = 'account-verification',
  RESET_PASSWORD_VERIFICATION = 'password-verification',
  FORGOT_PASSWORD = 'forgot-password',
  FORGOT_USERNAME = 'forgot-username',
  INVITE_USER = 'invite',
  ERROR_PAGE = 'pagenotfound',
  DASHBOARD = 'dashbord',
  CREATE_TEMPLATE = 'create-template',
  PREVIEW_TEMPLATE = 'view-template',
  EMAIL_CAMPAING = 'email-campaign',
  NOTIFICATIONS = 'notifications',
  USER_ACCOUNTS = 'user-accounts',
  RESET_PASSWORD = 'reset-password',
  EDIT_TEMPLATE = 'edit-template',
}

export enum SuperAdminBackOfficeRoute {
  TEMPLATE = 'super-admin/template',
}

export enum TechnicianRoute {
  CHECKLIST = 'technician/checklist',
}

export enum AdminBackOfficeRoute {
  TEMPLATE = 'admin/template',
}

export enum ServiceAdvisorRoute {
  PENDING_JOBS = 'service-advisor/pending-jobs',
}

export enum ReCaptchaActionType {
  USER_FORGOT_PASSWORD = 'USER_FORGOT_PASSWORD',
  JOIN_WAITLIST = 'JOIN_WAITLIST',
}

export enum ToastMessageType {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
}

export enum CommonStateType {
  MANAGE_CATEGORY_MODAL = 'MANAGE_CATEGORY_MODAL',
  MANAGE_LEFTSIDEBAR = 'MANAGE_LEFTSIDEBAR',
  TEMPLATE = 'TEMPLATE',
  TOAST_MESSAGE = 'TOAST_MESSAGE',
  CATEGORY_LIST = 'CATEGORY_LIST',
  MANAGE_TASKRULESIDEBAR = 'MANAGE_TASKRULESIDEBAR',
  TEMPLATE_STEP = 'TEMPLATE_STEP',
  MEDIA_MODAL = 'MEDIA_MODAL',
  MANAGE_BACKBUTTON = 'MANAGE_BACKBUTTON',
}

export enum EmptyCategoryListType {
  NO_SUBCATEGORY = '',
  NO_TASK = '',
}

export enum SupportedFileType {
  IMAGE = 'image',
  VIDEO = 'video',
  DOCS = 'docs',
  URL = 'url',
}

export enum ApiOrder {
  ASCE = 'asc',
  DESC = 'desc',
}

export enum TemplateOrderBy {
  CREATED_AT = 'template.createdAt',
  CREATED_BY = 'createdBy.firstName',
  DESCRIPTION = 'template.description',
  NAME = 'template.name',
  UPDATED_AT = 'template.updatedAt',
  INDUSTRY = 'industry.name',
}

export enum DeleteAccountType {
  SUPER_ADMIN = 'superAdmin',
  COMPANY_ACCOUNT = 'companyAccount',
}

export enum UserAccountOrderBy {
  COMPANY_NAME = 'name',
  FIRST_NAME = 'firstName',
  EMAIL = 'email',
  Role = 'role',
}

export enum CompanyAccountOrderBy {
  COMPANY_NAME = 'company.name',
  FIRST_NAME = 'billingContact.firstName',
  EMAIL = 'billingContact.email',
  PHONE = 'billingContact.phone',
  RENEWAL_DATE = 'companyTenant.renewalDate',
  CREATED_AT = 'company.createdAt',
}

export enum UserRole {
  SUPER_ADMIN = 'SUPER_ADMIN',
  MASTER_ADMIN = 'MASTER_ADMIN',
  ADMIN = 'ADMIN',
  COMPANY_ADMIN = 'COMPANY_ADMIN',
  TECHNICIAN = 'TECHNICIAN',
  SERVICE_ADVISOR = 'SERVICE_ADVISOR',
}

export const UserAdminRole = ['ADMIN', 'COMPANY_ADMIN'];

export enum TemplateActionType {
  CLONE_TEMPLATE = 'clone',
  EDIT_LIBRARY_FORM = 'EDIT_LIBRARY_FORM',
  ADD_TO_MY_TEMPLATE = 'ADD_TO_MY_TEMPLATE',
  RENAME_TEMPLATE = 'RENAME_TEMPLATE',
}

export enum ServiceAdvicerOrderBy {
  COMPANY_NAME = 'name',
  TECHNICIAN_NAME = 'technicianName',
  CUSTOMER_NAME = 'customerName',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  JOB_NUMBER = 'jobNumber',
}

export enum JobStatus {
  INCOMPLETE = 'INCOMPLETE',
  COMPLETED = 'COMPLETED',
  REQUESTED_FOR_APPROVAL = 'REQUESTED_FOR_APPROVAL',
  APPROVED = 'APPROVED',
}

export enum TabType {
  ADMIN_TEMPLATE = 'MY_TEMPLATE',
  LIBRARY = 'LIBRARY',
  ARCHIVED = 'RECENTLY_DELETED',
}

export enum UserAccountTabType {
  ACTIVE = 'ACTIVE',
  DEACTIVE = 'DEACTIVATE',
}
