import { handleAsyncThunk } from '../../Utils/service';
import { LandingPageRequestDto } from '../Interface/landingPage';

export const createWaitListCompany = async (body: LandingPageRequestDto) =>
  handleAsyncThunk('/company/wait-list', 'post', body);

// Get address prediction
export const getGoogleMapAddressSuggestion = async (data: { input: string }) =>
  handleAsyncThunk(
    `/google-maps/autocomplete?input=${data.input}&types=geocode&language=en&components=country:us|country:ca`,
    'get',
    '',
  );
